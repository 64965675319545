<template>
  <div class="page page__home">
    <section class="my-services">
      <div class="my-services__wrap">
        <div class="my-services__main">
          <div
            v-if="hasPopularServices"
            class="my-services__block my-services__popular"
          >
            <div class="my-services__title-block">
              <h2>Популярные услуги</h2>
            </div>
            <div class="my-services__favorites-cnt">
              <swiper
                :options="swiperFavoriteOption"
              >
                <swiper-slide
                  class="my-services__favorite"
                  v-for="service in popularServices"
                  :key="service.id"
                >
                  <popular-service
                    :service="service"
                  />
                </swiper-slide>
                <div class="swiper-pagination" slot="pagination" />
              </swiper>
            </div>
          </div>
          <div v-if="favoriteServices && !selectedUser" class="my-services__block my-services__favorites">
            <div class="my-services__title-block">
              <h2>Избранные услуги</h2>
              <esmp-link
                to="/account/my-favorites"
                v-if="favoriteServices.length"
                class="my-services__link"
              >
                <span>Все услуги</span>
                <esmp-icon name="arrow-long" />
              </esmp-link>
            </div>
            <div v-if="favoriteServices.length" class="my-services__favorites-cnt">
              <swiper
                :options="swiperFavoriteOption"
              >
                <swiper-slide
                  class="my-services__favorite"
                  v-for="service in favoriteServices"
                  :key="service.id"
                >
                  <favorite-service
                    :service="service"
                  />
                </swiper-slide>
                <div class="swiper-pagination" slot="pagination" />
              </swiper>
            </div>
            <filler-block
              v-else
              is-big
              icon="empty-favorites"
              :icon-width="251"
              title="Сохраняйте услуги в избранное"
              subtitle="Быстрый доступ к услугам, которыми часто пользуетесь"
            />
          </div>
          <div class="my-services__block my-services__news news-block">
            <template v-if="mappedNews.length">
              <div class="my-services__title-block">
                <h2>Новости</h2>
                <esmp-link
                  to="/news"
                  class="my-services__link"
                >
                  <span>Все новости</span>
                  <esmp-icon name="arrow-long" />
                </esmp-link>
              </div>
              <div class="tile-grid news-block__list">
                <faq-article-item
                  v-for="newsItem in mappedNews"
                  :key="newsItem.id"
                  :image-url="newsItem.mainImageUrl"
                  :title="newsItem.title"
                  :additional-info="newsItem.additionalInfo"
                  :url="newsItem.url"
                />
              </div>
              <swiper
                class="news-block__slider"
                :options="swiperOption"
              >
                <swiper-slide
                  v-for="newsItem in mappedNews"
                  :key="newsItem.id"
                >
                  <faq-article-item
                    :image-url="newsItem.mainImageUrl"
                    :title="newsItem.title"
                    :additional-info="newsItem.additionalInfo"
                    :url="newsItem.url"
                  />
                </swiper-slide>
              </swiper>
            </template>
            <filler-block
              v-else
              is-big
              icon="empty-news"
              :icon-width="251"
              title="Будь в курсе последних новостей"
              subtitle="Здесь будут отображаться <br>актуальные новости нашего сервиса"
            />
          </div>
        </div>

        <aside class="my-services__side">
          <template v-if="!selectedUser">
            <additional-documents
              v-if="excisedTemplates && excisedTemplates.length"
              class="my-services__block"
              title="Мои шаблоны"
              additional-link-title="Все шаблоны"
              additional-link-url="/account/my-templates"
              :is-sortable="true"
              :items="excisedTemplates"
              @sort-templates-order="sortTemplatesOrder"
            />

            <filler-block
              v-else
              class="my-services__block"
              icon="empty-templates"
              :icon-width="160"
              title="Мои шаблоны"
              subtitle="Здесь будут отображаться <br>ваши шаблоны заявок"
            />
          </template>

          <template v-if="postcardServices && postcardServices.length">
            <additional-documents
              class="my-services__block"
              title="Отправить открытку"
              :items="postcardServices"
            />
          </template>

          <div v-if="false" class="my-services__block">
            <div class="my-services__title-block">
              <h3>Полезные статьи</h3>
              <esmp-link
                to="/articles"
                class="my-services__link"
              >
                <span>Все статьи</span>
                <esmp-icon name="arrow-long" />
              </esmp-link>
            </div>
            <div class="my-services__articles-cnt">
              <article-list-item
                class="my-services__article"
                v-for="n in 4"
                :key="n"
                :id="n"
                :is-big="n === 2"
                :img="n !== 2 ? 'https://dummyimage.com/80/767/fff.png' : 'https://dummyimage.com/335/767/000.png'"
                title="«Ростелеком» выступил партнером Дня Военно-Морского Флота в Санкт-Петербурге"
              />
            </div>
          </div>
        </aside>
      </div>
    </section>
    <modal-feedback :is-show="isShowModalFeedback" @hide-modal="hideModalFeedback" />
    <esmp-loader v-if="loading" fix />
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from 'vue-awesome-swiper';
import 'swiper/css/swiper.css';
import { mapActions, mapGetters, mapState } from 'vuex';
import { MAX_FAVORITE_SERVICES_COUNT, MAX_TEMPLATES_COUNT } from '@/constants/main-page';
import { WINDOW_SIZES } from '@/constants/view-options';
import { getCreateTicketPath } from '@/helpers/pathGenerator';
import { getPaginatedArray } from '@/utils/pagination';
import { FaqArticleItem } from '@/components/faq';
import ModalFeedback from '@/components/modals/modal-feedback/';

const AdditionalDocuments = () => import('@/components/additional-documents');
const FavoriteService = () => import('@/components/favorite-service');
const PopularService = () => import('@/components/popular-service');
const FillerBlock = () => import('@/components/filler-block');
const ArticleListItem = () => import('@/components/article-list-item');

export default {
  name: 'Home',
  components: {
    FaqArticleItem,
    AdditionalDocuments,
    FavoriteService,
    FillerBlock,
    ArticleListItem,
    ModalFeedback,
    Swiper,
    SwiperSlide,
    PopularService,
  },
  data() {
    return {
      loading: false,
      isShowModalFeedback: false,
      swiperOption: {
        slidesPerView: 'auto',
        spaceBetween: 16,
        autoHeight: true,
      },
      swiperFavoriteOption: {
        slidesPerView: 4,
        spaceBetween: 16,
        watchOverflow: true,
        pagination: {
          el: '.swiper-pagination',
          clickable: true,
        },
        breakpoints: {
          [WINDOW_SIZES.tabletLandscape]: {
            slidesPerView: 4,
            slidesPerGroup: 4,
          },
          [WINDOW_SIZES.phone]: {
            slidesPerView: 3,
            slidesPerGroup: 3,
          },
          0: {
            slidesPerView: 2,
            slidesPerGroup: 2,
          },
        },
      },
    };
  },
  computed: {
    ...mapState('services', ['favoriteServices', 'popularServices']),
    ...mapState('userTemplates', ['templates']),
    ...mapState('user', ['selectedUser']),
    ...mapGetters('system', ['postcardServices']),
    ...mapGetters('news', ['mappedNews']),

    cuttedFavoriteServices() {
      return this.favoriteServices?.length
        ? getPaginatedArray(this.favoriteServices, 1, MAX_FAVORITE_SERVICES_COUNT)
        : [];
    },

    excisedTemplates() {
      return getPaginatedArray(this.templates, 1, MAX_TEMPLATES_COUNT)
        .map((template) => ({
          ...template,
          title: template.name,
          url: getCreateTicketPath(template.serviceId, template.sourceSystem, template.id),
        }));
    },
    hasPopularServices() {
      return this.popularServices?.length && !this.selectedUser;
    },
  },
  methods: {
    ...mapActions('system', ['setLoading']),
    ...mapActions('services', ['getFavorites', 'getPopular']),
    ...mapActions('news', ['getNews']),
    ...mapActions('userTemplates', ['getUserTemplates', 'updateSortOrderTemplates']),
    hideModalFeedback(payload) {
      this.isShowModalFeedback = payload.newValue;
    },
    sortTemplatesOrder(templates) {
      const localTemplates = templates.concat(this.templates.slice(MAX_TEMPLATES_COUNT));
      this.updateSortOrderTemplates(localTemplates.map((item, index) => ({
        ...item,
        sortOrder: index,
      })));
    },
  },
  created() {
    this.loading = true;

    Promise.all([
      this.getFavorites(),
      this.getPopular(),
      this.getNews({ state: 'actual', isMain: true, isPublic: true }),
      this.getUserTemplates(),
    ]).finally(() => {
      this.loading = false;
    });
  },
};
</script>

<style lang="scss">
.my-services {
  width: 100%;
  margin: 0 auto;

  &__wrap {
    display: grid;
    gap: $gap;
    grid-template-columns: calc(100% - #{$gap} - #{$right-column-width}) $right-column-width;
    grid-auto-rows: max-content;

    @include for-size(phone-landscape-down) {
      grid-template-columns: 100%;
      gap: calc(#{$gap} / 2);
    }
  }

  &__main {
    display: grid;
    gap: $gap;
    grid-template-columns: 100%;
    grid-auto-rows: max-content;
    @include for-size(phone-landscape-down) {
      order: 2;
      gap: calc(#{$gap} / 2);
    }
  }

  &__side {
    display: grid;
    gap: $gap;
    grid-template-columns: 100%;
    grid-auto-rows: max-content;
    @include for-size(phone-landscape-down) {
      order: 1;
      gap: calc(#{$gap} / 2);
    }
  }

  &__title-block {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
  }

  &__articles-cnt {
    @include for-size(phone-landscape-down) {
      row-gap: 20px;
      columns: 2;
      .article-item {
        page-break-inside: avoid;
        break-inside: avoid-column;
        display:table;
      }
    }

    @include for-size(phone-portrait-down) {
      columns: unset;
      row-gap: unset;
    }
  }

  &__favorites-cnt {
    .swiper-pagination {
      position: relative;
      bottom: unset;
      left: unset;
      margin-top: $base-gutter;
    }

    .swiper-pagination-bullet-active {
      background: $color-client-portal-logo;
    }
  }

  &__favorite {
    border-radius: 12px;
    height: auto;

    .favorite-service {
      display: block;
      height: 100%;

      &:hover {
        box-shadow: $base-shadow-m;
      }
    }
  }

  &__news {
    .tile-grid {
      grid-template-columns: 1fr 1fr;

      @include for-size(phone-portrait-down) {
        grid-template-columns: 1fr;
      }
    }
  }

  &__news-cnt {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: stretch;
    margin-top: -8px;
  }

  &__article {
    &:not(:last-child) {
      margin-bottom: 16px;
    }
  }

  .news-block {
    &__list {
      @include for-size(phone-portrait-down) {
        display: none;
      }
    }

    &__slider {
      display: none;
      @include for-size(phone-portrait-down) {
        display: block;
        width: 100vw;
        margin-left: -20px;
        padding-left: 20px;
        padding-right: 20px;
      }

      .swiper-slide {
        width: 90% !important;
      }
    }
  }

  &__link {
    display: flex;
    align-items: center;

    span {
      padding-right: 4px;
    }
  }
  &__popular {
    .swiper-container {
      padding-bottom: 10px;
    }
  }
}

.surveys {

}

</style>
